import React from "react"
import LayoutA3 from "../../components/layout-a3"
import homeStyles from "../themeA3/a3_home.module.css"
import CountDownA3 from "../../components/countdown-a3"
import FastEntryA3 from "../../components/fast-entry-a3"
import NewsA3 from  '../../components/news-a3'
import WonderfulPhotosA3 from '../../components/wonderful-photos-a3'
import SupportersA3 from '../../components/supporters-a3'

export default  class A3Home extends React.Component {
  render() {

    const color='#7fc527'
    return(
      <LayoutA3 >
        <div className={homeStyles.poster}>
        </div>
        <div className={homeStyles.countdown}>
          <CountDownA3 count={1000}/>
        </div>
        <div className={homeStyles.fastentry} style={{background:color}}>
          <img alt="快速入口" className={homeStyles.bgimg} src={require('../../images/a3/bg.png')}/>
          <FastEntryA3 />
        </div>
        <div style={{background:color}}>
          <NewsA3 />
        </div>
       <WonderfulPhotosA3 />
       <SupportersA3 />
      </LayoutA3>
    )
  }
}