import newsA3 from './news-a3.module.css'
import React from "react"

export default class NewsA3 extends React.Component {
  render() {
    const secondcolor='#64aa0e'
    return (
      <div className={newsA3.newsNotices}>
        <div className={newsA3.newsContainer} >
          <div className={newsA3.news}>
              <div  className={newsA3.newsTitle}>新闻</div>
              <div className={newsA3.newslist}>
                <div className={newsA3.newsItem}><a>1、新闻一新闻一新闻一新闻一新闻一新闻一新闻一</a></div>
                <div className={newsA3.newsItem}><a>2、新闻一新闻一新闻一新闻一</a></div>
                <div className={newsA3.newsItem}><a>3、新闻一新闻一新闻一新闻一</a></div>
              </div>
          </div>
        </div>



        <div className={newsA3.noticesContainer}>

          <div className={newsA3.notices}>
              <div className={newsA3.noticeslist} >
                <div className={newsA3.noticeItem}><a>1、公告一公告一公告一公告一</a></div>
                <div className={newsA3.noticeItem}><a>2、公告二公告一公告一公告一</a></div>
                <div className={newsA3.noticeItem}><a>3、公告二公告一公告一公告一</a></div>
              </div>
              <div  className={newsA3.noticeTitle}>公告</div>
          </div>
        </div>


      </div>

    )
  }
}